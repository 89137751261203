import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import StarIcon from '@material-ui/icons/Star';

export function Leaderboard(props) {

    const { currentUser, score, handlePlayAgain } = props;

    const classes = useStyles();

    const [boardLoading, setBoardLoading] = useState(false);
    const [leaders, setLeaders] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        setBoardLoading(true)

        axios
            .get('https://gameapi.appliedfooddiagnostics.com/api/users/findLeaders')

            .then(res => {
                console.log('REQUEST SENT')
                setLeaders(res.data)
                setBoardLoading(false)
            })

            .catch(err => {
                console.log('error', err)
                setBoardLoading(false)
            })
    }, []);

    return (
        <div>

            <Typography className={classes.thanks} variant='h4'>Thank you for playing!</Typography>

            <div className={classes.offByContainer}>
                <Typography variant='h5'>{currentUser.data.username}, you were off by</Typography>
                <Typography variant='h3'>{score && score} CFU</Typography>

            </div>
            {console.log('Leaders', leaders)}

            <div className={classes.leaderboard}>
                <Typography variant='h4'>Leaderboard</Typography>
                <TableContainer className={classes.table} component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            {(leaders && leaders.length > 0) && leaders.map((leader, index) => {
                                return (
                                    <TableRow
                                        className={index === 0 ? classes.firstRow : index === 1 ? classes.secondRow : index === 2 && classes.thirdRow}
                                        key={index}
                                    >
                                        <TableCell align='right'>{index + 1}</TableCell>

                                        <TableCell align='center' >
                                            {(index === 0 || index === 1 || index === 2) &&
                                                <StarIcon fontSize={'large'} style={{ fill: index === 0 ? 'gold' : index === 1 ? 'silver' : '#cd7f32' }} />
                                            }
                                        </TableCell>

                                        <TableCell>{leader.username}</TableCell>
                                        <TableCell>{leader.score}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant='caption'>The lower the score, the better!</Typography>

            </div>
            <Typography variant='h5'>Keep playing to improve your overall score.</Typography>
            <Button variant='contained' color='primary' className={classes.playAgainButton} onClick={handlePlayAgain} >Play Again</Button>
        </div >
    );
}

const useStyles = makeStyles({
    firstRow: {
        borderLeft: 'solid',
        borderLeftWidth: 20,
        borderColor: 'gold',
        backgroundColor: 'rgba(255,215,0,.25)'
    },
    secondRow: {
        borderLeft: 'solid',
        borderLeftWidth: 20,
        borderColor: 'silver',
        backgroundColor: 'rgba(211,211,211,.25)'
    },
    thirdRow: {
        borderLeft: 'solid',
        borderLeftWidth: 20,
        borderColor: '#cd7f32',
        backgroundColor: 'rgba(205, 127, 50,.25)'
    },
    thanks: {
        marginBottom: 20
    },
    offByContainer: {
        marginBottom: 20
    },
    table: {
        maxWidth: 500,
        display: 'table',
        margin: '0 auto',
    },
    leaderboard: {
        marginBottom: 20
    },
    playAgainButton: {
        textTransform: 'none',
    },
    textFieldContainer: {
        marginBottom: 20,
        marginTop: 20
    },
    textField: {
        width: '100%',
    },
    container: {
        width: '100%',
        height: '100%',
        marginTop: 50,
    },
    countdownContainer: {
        width: '100%',
        height: '100%',
    },
    stepperContainer: {
        maxWidth: 400,
        display: 'table',
        margin: '0 auto',
        // alignItems: 'center'
    },
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    imageContainer: {
        height: 300,
        maxWidth: 400,
        // overflow: 'hidden',
        // display: 'block',
        width: '100%',
    },
    answerContainer: {
        height: 300,
        maxWidth: 400,
        // overflow: 'hidden',
        // display: 'block',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    countdownNumber: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    }
});