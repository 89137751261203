import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function CircularProgressWithLabel(props) {

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value / 10,
                )}`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function CircularStatic(props) {
    const [progress, setProgress] = React.useState(props.time ? props.time : 80);

    const { handleTimeout } = props;

    React.useEffect(() => {
        let counter = 0;
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress - 10));
            counter++;
            if (!props.time && counter === 8) {
                clearInterval(timer);
                handleTimeout()
            }
            else if (props.time && counter === props.time/10) {
                clearInterval(timer);
                handleTimeout()
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [handleTimeout]);

    return <CircularProgressWithLabel value={progress} />;
}