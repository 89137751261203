import './App.css';
import { Game } from './components/pages/Game'
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import increaseSpecificity from 'jss-increase-specificity';
import { create } from 'jss';

function App() {

  const jss = create({
    plugins: [...jssPreset().plugins, increaseSpecificity({ repeat: 1 })],
  });

  return (
    <StylesProvider jss={jss}>
      <div className="App">
        <Game />
      </div>
    </StylesProvider>
  );
}

export default App;
