import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { SignUpForm } from './SignUpForm'
import Zoom from '@material-ui/core/Zoom';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageCountdown from '../shared/ImageCountdown'
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { Leaderboard } from './Leaderboard'
import easyCountImage from '../../assets/easyCount.jpg'
import mediumCountImage from '../../assets/mediumCount.jpg'
import mediumHardCountImage from '../../assets/mediumHardCount.jpg'
import hardCountImage from '../../assets/hardCount.jpg'
import easyCountImage2 from '../../assets/easyCount2.jpg'

export function Game() {

    const classes = useStyles();

    const [signUpOpen, setSignUpOpen] = useState(true);
    // const [startCountdown, setStartCountdown] = useState(false);
    const [countdownValue, setCountdownValue] = useState(3);
    const [timer, setTimer] = useState(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const [countField, setCountField] = React.useState('');
    const [answerArray, setAnswerArray] = React.useState([]);
    const [countFieldError, setCountFieldError] = React.useState(false);
    const [submitQuizLoading, setSubmitQuizLoading] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState(null);
    const [showBoard, setShowBoard] = React.useState(false);
    const [showGame, setShowGame] = React.useState(false);
    const [score, setScore] = React.useState(null);
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const [imageLoading, setImageLoading] = React.useState(true);
    const [showImage, setShowImage] = React.useState(true);

    const [imageLoad2, setImageLoad2] = React.useState(false);
    const [imageLoad3, setImageLoad3] = React.useState(false);
    const [imageLoad4, setImageLoad4] = React.useState(false);
    const [imageLoad5, setImageLoad5] = React.useState(false);
    const [imgsLoaded, setImgsLoaded] = useState(false)

    const maxSteps = imageSteps.length;

    useEffect(() => {
        if (answerArray.length === 5) {
            console.log('Send Answers', answerArray);
            console.log('QUIZ SUBMITTED');
            let sendData = {};

            sendData.answers = answerArray;
            sendData.email = currentUser.data.email;
            sendData.fullName = currentUser.data.fullName;
            sendData.gamesWon = currentUser.data.gamesWon;
            sendData.gamesPlayed = currentUser.data.gamesPlayed;
            sendData.score = currentUser.data.score;

            console.log('SENDDATA', sendData)

            axios
                .post('https://gameapi.appliedfooddiagnostics.com/api/users/newSubmission', sendData)

                .then(res => {
                    setCountField('');
                    console.log('REQUEST SENT', res);
                    setScore(res.data);
                    setSignUpOpen(false);
                    setSubmitQuizLoading(false);
                    setShowGame(false);
                    setShowBoard(true);
                })

                .catch(err => {
                    console.log('error', err);
                    setSignUpOpen(false);
                    setSubmitQuizLoading(false);
                    // setStartCountdown(true);
                })
        }
    }, [answerArray]);



    function handleSignUpClose(value) {
        setSignUpOpen(value);
        setShowGame(true);

        // let counter = 0;
        // let countdownInterval = setInterval(handleCountdown, 1000);

        // function handleCountdown() {
        //     counter++;
        //     if (counter === 4) {
        //         console.log('ENDED');
        //         clearInterval(countdownInterval);
        //         // setStartCountdown(false);
        //         setShowGame(true);
        //         setTimer(true);
        //     }
        //     setCountdownValue(countValue => countValue - 1);
        // }
    }

    function handleTimeout() {
        setTimer(false);
        setImageLoading(false);
        setShowImage(false);
    }

    function handleSubmitAnswer(e) {
        e.preventDefault();
        if (!countField) {
            setCountFieldError(true);
        }
        else {
            let truncatedCountField = Math.trunc(countField);
            setImageLoaded(true);
            setShowImage(true);

            setCountFieldError(false);
            setAnswerArray(array => [...array, truncatedCountField]);
            console.log('answer submitted');
            setTimer(true);
            setImageLoading(true);
            setCountField('');
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    function handleCountChange(e) {
        e.preventDefault()
        if (countFieldError)
            setCountFieldError(false);
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setCountField(e.target.value);
        }
        else
            setCountField('');
    }

    function handleSubmitQuiz(e) {
        e.preventDefault()
        if (!countField) {
            setCountFieldError(true);
        }
        else {
            let truncatedCountField = Math.trunc(countField);
            setCountFieldError(false);
            setAnswerArray(array => [...array, truncatedCountField]);
        }
    }

    function handlePlayAgain() {
        let sendData = {};
        sendData.firstName = currentUser.data.firstName
        sendData.lastName = currentUser.data.lastName

        sendData.fullName = currentUser.data.fullName
        sendData.email = currentUser.data.email

        //newUser finds user and creates one if they don't exist
        axios
            .post('https://gameapi.appliedfooddiagnostics.com/api/users/newUser', sendData)

            .then(res => {
                console.log('CURRENT USER', res);
                setCurrentUser(res);
                setSignUpOpen(false);
                // setStartCountdown(false);
                setShowGame(true);
                setTimer(true);
                setActiveStep(0);
                setShowBoard(false);
                setAnswerArray([]);
            })

            .catch(err => {
                console.log('error setting user', err);
            })
    }

    function onLoad() {
        // setImageLoaded(true)
        setTimer(true);
        setShowImage(true);

        // switch (activeStep) {
        //     case 1:
        //         setImageLoad1(true)
        //     case 2:
        //         setImageLoad2(true)
        //     case 3:
        //         setImageLoad3(true)
        //     case 4:
        //         setImageLoad4(true)
        //     case 5:
        //         setImageLoad5(true)
        // }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const loadImage = image => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image()
                loadImg.src = image
                // wait 2 seconds to simulate loading time
                loadImg.onload = () =>
                    setTimeout(() => {
                        resolve(image)
                    }, 2000)

                loadImg.onerror = err => reject(err)
            })
        }

        Promise.all(imageSteps.map(image => loadImage(image.imgPath)))
            .then(() => setImgsLoaded(true))
            .catch(err => console.log("Failed to load images", err))
    }, [])

    return (
        <div className={classes.container}>
            {showGame && !signUpOpen && imgsLoaded &&
                <div>
                    <Typography variant='h4'>Count the Colonies!</Typography>
                    <form onSubmit={e => (!timer && activeStep === 2) ? handleSubmitQuiz(e) : !timer && handleSubmitAnswer(e)}>
                        <div className={classes.stepperContainer}>
                            {timer ?
                                <div className={classes.imageContainer}>
                                    <Paper square elevation={0} className={classes.header}>
                                        <Typography>{imageSteps[activeStep].label}</Typography>
                                    </Paper>
                                    <img
                                        className={classes.image}
                                        src={imageSteps[activeStep].imgPath}
                                        alt={imageSteps[activeStep].label}
                                    />
                                </div>
                                :

                                <div className={classes.answerContainer}>
                                    <Slide in={!timer}>
                                        <Paper square elevation={0} className={classes.header}>
                                            <div>
                                                <Typography>What is the colony count?</Typography>
                                            </div>
                                            <div className={classes.countTextField}>
                                                <TextField
                                                    inputProps={{ maxLength: 10 }}
                                                    type='number'
                                                    inputProps={{ inputMode: 'numeric' }}
                                                    autoFocus
                                                    label='Count'
                                                    value={countField}
                                                    onChange={handleCountChange}
                                                    error={countFieldError}
                                                    helperText={countFieldError && 'Enter a count'}
                                                    variant='outlined'
                                                />
                                            </div>
                                        </Paper>
                                    </Slide>
                                </div>
                            }
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                variant="text"
                                activeStep={activeStep}
                                nextButton={
                                    timer ?
                                        <div>
                                            <ImageCountdown time={activeStep === 4 && 150} handleTimeout={handleTimeout} />
                                        </div>
                                        :
                                        (!timer && activeStep === 4) ?
                                            <Button
                                                type='submit'
                                                className={classes.submitButton}
                                                variant='contained'
                                                color='primary'
                                                onClick={handleSubmitQuiz}
                                            >
                                                Submit and Finish
                                            </Button> :
                                            (!timer && activeStep === 4 && submitQuizLoading) ?
                                                <CircularProgress className={classes.countdownNumber} color="primary" />
                                                :
                                                !timer &&
                                                <Button
                                                    type='submit'
                                                    className={classes.submitButton}
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={handleSubmitAnswer}
                                                >
                                                    Submit Answer
                                                </Button>
                                }
                            />

                        </div>
                    </form>
                </div>
            }
            {showBoard &&
                <Leaderboard handlePlayAgain={handlePlayAgain} score={score} currentUser={currentUser} />
            }

            {/* {(startCountdown && !signUpOpen) &&
                <div className={classes.countdownContainer}>
                    <Zoom className={classes.countdownNumber} in={countdownValue === 3}>
                        <Typography variant='h4'>3</Typography>
                    </Zoom>
                    <Zoom className={classes.countdownNumber} in={countdownValue === 2}>
                        <Typography variant='h4'>2</Typography>
                    </Zoom>
                    <Zoom className={classes.countdownNumber} in={countdownValue === 1}>
                        <Typography variant='h4'>1</Typography>
                    </Zoom>
                    <Zoom className={classes.countdownNumber} in={countdownValue === 0}>
                        <Typography variant='h4'>COUNT!</Typography>
                    </Zoom>
                </div>
            } */}
            <SignUpForm
                // setStartCountdown={value => setStartCountdown(value)}
                signUpOpen={signUpOpen}
                setSignUpOpen={value => handleSignUpClose(value)}
                setCurrentUser={value => setCurrentUser(value)}
            />
        </div>
    );
}

const imageSteps = [
    {
        label: 'Plate 1',
        imgPath:
            easyCountImage,
    },
    {
        label: 'Plate 2',
        imgPath:
            easyCountImage2,
    },
    {
        label: 'Plate 3',
        imgPath:
            mediumCountImage,
    },
    {
        label: 'Plate 4',
        imgPath:
            mediumHardCountImage,
    },
    {
        label: 'Plate 5',
        imgPath:
            hardCountImage,
    },
];

const useStyles = makeStyles({
    countTextField: {
        marginTop: 20
    },
    submitButton: {
        textTransform: 'none'
    },
    textFieldContainer: {
        marginBottom: 20,
        marginTop: 20
    },
    textField: {
        width: '100%',
    },
    container: {
        width: '100%',
        height: '100%',
        // marginTop: 50,
    },
    countdownContainer: {
        position: 'relative'
    },
    stepperContainer: {
        maxWidth: 400,
        display: 'table',
        margin: '0 auto',
        height: 500
        // alignItems: 'center'
    },
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    imageContainer: {
        maxWidth: 325,
        // overflow: 'hidden',
        // display: 'block',
        width: '100%',
    },
    image: {
        width: '100%'
    },
    answerContainer: {
        height: 300,
        maxWidth: 400,
        // overflow: 'hidden',
        // display: 'block',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    countdownNumber: {
        display: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        overflow: 'auto',
        height: '50%',
        width: '50%'
        // margin: 'auto',
        // position: 'absolute',
        // top: 0,
        // left: 0,
        // bottom: 0,
        // right: 0,
    }
});