import React, { useState, useEffect } from 'react';
import { Divider, TextField, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

export function SignUpForm(props) {

    const { setCurrentUser, signUpOpen, setSignUpOpen } = props;

    const classes = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailIncorrectError, setEmailIncorrectError] = useState(false);

    const [submitFormLoading, setSubmitFormLoading] = useState(false);

    function handleSubmitForm(e) {
        e.preventDefault();

        if (!firstName) {
            setFirstNameError(true)
        }
        if (!lastName) {
            setLastNameError(true)
        }
        if (!companyName) {
            setCompanyNameError(true)
        }
        if (!email) {
            setEmailError(true)
        }
        if (!email.includes("@")) {
            setEmailIncorrectError(true)
        }

        if (firstName && lastName && companyName && email && email.includes("@")) {
            setSubmitFormLoading(true)
            let sendData = {};
            sendData.firstName = firstName;
            sendData.lastName = lastName;
            sendData.company = companyName;
            sendData.email = email;

            axios
                .post('https://gameapi.appliedfooddiagnostics.com/api/users/newUser', sendData)

                .then(res => {
                    console.log('REQUEST SENT');
                    setSignUpOpen(false);
                    setSubmitFormLoading(false);
                    // setStartCountdown(true);
                    setCurrentUser(res);
                })

                .catch(err => {
                    console.log('error', err);
                    setSignUpOpen(false);
                    setSubmitFormLoading(false);
                    // setStartCountdown(true);
                })
        }
    }

    function handleFirstNameChange(e) {
        e.preventDefault()

        if (firstNameError)
            setFirstNameError(false)
        setFirstName(e.target.value)
    }
    function handleLastNameChange(e) {
        e.preventDefault()

        if (lastNameError)
            setLastNameError(false)
        setLastName(e.target.value)
    }
    function handleCompanyNameChange(e) {
        e.preventDefault()

        if (companyNameError)
            setCompanyNameError(false)
        setCompanyName(e.target.value)
    }
    function handleEmailChange(e) {
        e.preventDefault()

        if (emailError || emailIncorrectError)
            setEmailError(false)
        setEmailIncorrectError(false)
        setEmail(e.target.value)
    }

    return (
        signUpOpen &&
        <div className={classes.container}>
            {/* <Dialog aria-labelledby="simple-dialog-title" open={signUpOpen}> */}
            <form onSubmit={handleSubmitForm}>
                {/* <DialogTitle id="simple-dialog-title">Enter the Contest</DialogTitle> */}
                <Typography variant='h5'>Enter the Contest</Typography>
                {/* <DialogContent> */}
                <Typography variant='paragraph'>
                    Complete the fields below to compete in Applied Food Diagnostics' colony counting game for your chance to win.
                    If you are a returning player, your score is saved and you can continue improving your score!
                </Typography>
                <div className={classes.textFields}>
                    <TextField
                        disabled={submitFormLoading}
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                        // className={classes.textField}
                        label='First Name'
                        onChange={handleFirstNameChange}
                        value={firstName}
                        error={firstNameError}
                        helperText={firstNameError && 'This field is required'}
                        variant="outlined"
                        required
                    />
                </div>
                <div className={classes.textFieldContainer}>
                    <TextField
                        disabled={submitFormLoading}
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                        // className={classes.textField}
                        label='Last Name'
                        onChange={handleLastNameChange}
                        value={lastName}
                        error={lastNameError}
                        helperText={lastNameError && 'This field is required'}
                        variant="outlined"
                        required
                    />
                </div>
                <div className={classes.textFieldContainer}>
                    <TextField
                        disabled={submitFormLoading}
                        fullWidth
                        inputProps={{ maxLength: 100, inputMode: 'email' }}
                        // className={classes.textField}
                        label='Email Address'
                        onChange={handleEmailChange}
                        value={email}
                        error={emailError || emailIncorrectError}
                        helperText={emailError ? 'This field is required' : emailIncorrectError && 'Please use a valid email address'}
                        variant="outlined"
                        required
                    />
                </div>
                <div>
                    <TextField
                        disabled={submitFormLoading}
                        fullWidth
                        inputProps={{ maxLength: 155 }}
                        // className={classes.textField}
                        label='Company Name'
                        onChange={handleCompanyNameChange}
                        value={companyName}
                        error={companyNameError}
                        helperText={companyNameError && 'This field is required'}
                        variant="outlined"
                        required
                    />
                </div>

                {/* </DialogContent> */}
                {/* <DialogActions> */}
                {submitFormLoading &&
                    <CircularProgress className={classes.countdownNumber} color="primary" />
                }
                <Button type='submit' className={classes.playButton} variant='contained' disabled={submitFormLoading} onClick={handleSubmitForm} color="primary">
                    Play Game
                </Button>
                <div className={classes.helperText} >
                    <Typography variant='caption'>*A random username will be generated for you. None of your information will be visible to others playing the game.</Typography>
                </div>
                {/* </DialogActions> */}

                <Divider className={classes.divider} />

                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>
                        NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. A PURCHASE OR CONTRIBUTION TO APPLIED FOOD DIAGNOSTICS WILL NOT INCREASE YOUR CHANCES OF WINNING.
                    </Typography>
                </div>
                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>
                        Eligibility
                    </Typography>
                    <Typography className={classes.termsText} variant='caption'>
                        Promotions are open to legal residents of the 50 United States and the District of Columbia, who are 18 years of age or older as of the date of entry, are attendees of the IAFP annual meeting 2021, and are void where prohibited by law. The promotion is subject to all federal, state, local, municipal laws and regulations.
                    </Typography>
                </div>

                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>
                        Promotion Period – July 18-20, 2021
                    </Typography>

                    <Typography className={classes.termsText} variant='caption'>
                        Entries will be accepted online starting and ending on the dates and times specified in the linked announcement for the promotion (e.g., the post on our LinkedIn Page.) Entries that are submitted before or after the Promotion Period will be disqualified.
                    </Typography>
                </div>

                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>
                        Entry rules
                    </Typography>

                    <Typography className={classes.termsText} variant='caption'>
                        The promotion must be entered by following the entry rules specified in the linked announcement for the promotion (e.g., the post on our LinkedIn Page.) Incomplete entries or the ones which are not in alignment with the rules, requirements, and specifications will be disqualified by Applied Food Diagnostics. You may enter the promotion as many times as specified in the linked announcement for the promotion (e.g., the post on our LinkedIn Page,) and you need to provide any information that might be necessary. Any attempt of circumvention of the rules will result in immediate disqualification from the promotion.
                    </Typography>
                </div>

                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>
                        Web Entries
                    </Typography>

                    <Typography className={classes.termsText} variant='caption'>
                        Participants must accurately complete all required fields or the entry will be characterized as incomplete and will not qualify.
                    </Typography>
                </div>

                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>
                        Disclaimer of Responsibility
                    </Typography>

                    <Typography className={classes.termsText} variant='caption'>
                        Applied Food Diagnostics is not responsible for problems with promotion entries, including but not

                        limited to, entries which are lost, late, misdirected, damaged, incomplete, illegible, or cannot be

                        completed due to electronic or technical difficulties, even if the problem is the result of the sole

                        or partial negligence of Applied Food Diagnostics.
                    </Typography>
                </div>

                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>
                        Prizes
                    </Typography>

                    <Typography className={classes.termsText} variant='caption'>
                        Winner of the promotion (hereinafter “Winner”) will receive the prize specified in the linked announcement for the promotion (e.g., the post on our LinkedIn Page,) which is determined by Applied Food Diagnostics. The prize cannot be redeemed for cash or substituted for another prize, and is non-transferable. Prize-related expenses including all federal, state, and local taxes represent the sole financial responsibility of the Winner. The prize is given with no written, express, or implied warranty. Any prize not claimed within 14 days will be forfeited by the winner and remain the property of Applied Food Diagnostics. Acceptance of the prize gives Applied Food Diagnostics permission to use the Winner’s personal details and comment, photo, audio file, video, text, etc.] for advertising purposes without further compensation, unless it is prohibited by law.
                    </Typography>
                </div>

                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>
                        Winner Selection and Notification
                    </Typography>
                    <Typography className={classes.termsText} variant='caption'>
                        Winner will be selected by criteria specified in the linked announcement for the promotion (e.g., the post on our LinkedIn Page,) under the supervision of Applied Food Diagnostics. Winner will be informed about the prize by a social media response to your entry, private social media message, or email within 5 business days following the Winner selection to claim the prize. Applied Food Diagnostics is not responsible if the Winner fails to receive the notice about the prize due to any security settings or provision of incorrect contact information. If the Winner fails to respond within the required time period, the prize may be forfeited and an alternate Winner may be selected based on the original criteria specified in the linked announcement for the promotion. cceptance of the prize given in this promotion is in accordance with any and all federal, state, and local laws and regulations. If the Winner is disqualified for any reason, Applied Food Diagnostics is not obliged to provide an alternate prize or extend the promotion period in any way, and all privileges as Winner will be terminated immediately. Participants who provide false, fraudulent, or deceptive entries or who engage in false, fraudulent or deceptive acts in connection with the promotion will be disqualified and subject to criminal prosecution.
                    </Typography>
                </div>

                <div className={classes.terms}>
                    <div>
                        <Typography className={classes.termsText} variant='caption'>
                            How to Claim the Prize
                        </Typography>
                    </div>
                    <div>
                        <Typography className={classes.termsText} variant='caption'>
                            Prizes will be mailed or emailed to the winners.
                        </Typography>
                    </div>
                </div>

                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>
                        Terms and General Conditions
                    </Typography>
                    <Typography className={classes.termsText} variant='caption'>
                        Applied Food Diagnostics reserves the right to cancel, terminate, modify or suspend the promotion should virus, bug, non-authorized human intervention, fraud, or any other cause affect the administration, security, fairness, or proper conduct of the promotion. Any attempt by an entrant to deliberately damage the Winner selection process and the legitimate operation of the promotion may be a violation of criminal and civil laws.
                    </Typography>
                </div>

                <div className={classes.terms}>
                    <Typography className={classes.termsText} variant='caption'>

                        Limitation of Liability
                    </Typography>
                    <Typography className={classes.termsText} variant='caption'>
                        By entering the promotion, You agree to release and hold harmless Applied Food Diagnostics and its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assigns, employees, officers, and directors from any liability, illness, injury, death, loss, litigation, claim, or damage that may occur, directly or indirectly, whether caused by negligence or not, from:

                        such entrant’s participation in the promotion and/or his/her acceptance, possession, use, or misuse of any prize or any portion thereof;

                        technical failures of any kind, including but not limited to the malfunction of any computer, cable, network, hardware, software, or other mechanical equipment;

                        the unavailability or inaccessibility of any transmissions, telephone, or Internet service;

                        unauthorized human intervention in any part of the entry process or the promotion;

                        electronic or human error in the administration of the promotion or the processing of entries.

                        Disputes and Arbitration

                        THIS PROMOTION IS GOVERNED BY THE LAWS OF THE UNITED STATES OF AMERICA AND PENNSYLVANIA, WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. As a condition of participating in this promotion, the participant agrees that any and all disputes that cannot be resolved between the parties, and causes of action arising out of or connected with this promotion, shall be resolved individually, without resort to any form of class action, exclusively before a court located in PENNSYLVANIA having jurisdiction.
                    </Typography>
                </div>


            </form>
            {/* // </Dialog> */}
        </div >
    );
}

const useStyles = makeStyles({
    textFields: {
        marginTop: 20
    },
    divider: {
        marginTop: 50,
        marginBottom: 50
    },
    termsText: {
        display: 'inline-block',
    },
    terms: {
        margin: 10
    },
    helperText: {
        marginTop: 20
    },
    playButton: {
        textTransform: 'none',
        marginTop: 20,
    },
    textFieldContainer: {
        marginBottom: 20,
        marginTop: 20
    },
    textField: {
        width: '100%',
    },
    container: {
        margin: 20,
        height: "100%",
        textAlign: 'left'
    },
    heightFix: {
        height: "100%",
        width: "100%",
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    countdownNumber: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    }
});