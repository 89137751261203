import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { StylesProvider } from '@material-ui/core/styles';

const target = document.getElementById('erw-root');

if (target) {
  // ReactDOM.render(<App />, target); 
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
    ,
    target
  );
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

